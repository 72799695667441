@import "../../index.less"; 

.home_layout {
  background: rgb(255, 255, 255);
  height: 100vh;
}

.navbar {
  background: #fff;
}

.button {
  align-self: center;
}

.logo {
  float: left;
  margin: 16px 24px 16px 0;
}

#components-layout-demo-top-side-2 {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
  width: 100%;
}

.home_content {
  width: 100%;
}

.dashboard_row {
  background-color: #fff;
  align-items: center;
  align-content: center;
  padding: 20px 20px 20px 20px;
}

.userApiId {
  border: 1px solid @secondary-color;
  border-radius: 25px;
  padding: 10px 10px 10px 10px;
}

/* ---------- Custom scrollbar design ----------- */

::-webkit-scrollbar {
  width: 20px; /* For the size of vertical scrollbar */
  height: 20px; /* For the size of horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/*-------- Grid View -------*/
.grid-layout {
  background: #fff;
}

@primary-color: #270949;@tabs-title-font-size-sm: 12px;