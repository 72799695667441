// Header
#appbar {
  position: fixed;
  z-index: 1;
  width: 100%;

  #caramel-analytic-logo {
    float: left;
    margin: 16px 24px 16px 0;
    width: 180px;

    .text {
      color: white;
    }
  }

  #menu {
    width: 50%;
    display: inline-block;
  }

  .appbar-icon {
    :hover {
      cursor: pointer;
    }
  }

  .avatar-group {
    margin-right: 10px;
  }

  .add-icon {
    margin: 10px;
    font-size: medium;

    :hover {
      color: #1da57a;
      cursor: pointer;
    }
  }

  .vertical-divider {
    width: 2px;
    height: 44px;
    margin: 10px 0px;
    background-color: white;
  }

  .right-align {
    float: right;
    margin-left: 15px;
    display: block;
  }

  .profile-icon {
    .icon {
      align-self: center;
      vertical-align: middle;
      margin-bottom: 5px;
    }
  }
}

.content {
  margin-top: 35px;
  padding: 0px 10px 10px 10px;
  overflow: hidden;
}

.embedded-report {
  height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
}

@primary-color: #270949;@tabs-title-font-size-sm: 12px;