/*-------- Pricing --------*/

.pricing_row {
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 20px 20px 20px 20px;
    width: 100%;
  }
  
  .pricing_col {
    padding: 40px 40px 40px 40px;
  }
  
  .pricing_content {
    padding: 20px 20px 20px 20px;
    background-color: #a8bbbf;
    border-radius: 10px;
    height: 500px;
  }
  
  .pricing_content_title {
    height: 110px;
  }
  
  .pricing_content_price {
    height: 50px;
  }
  
  .pricing_content_nav {
    height: 100px;
  }
@primary-color: #270949;@tabs-title-font-size-sm: 12px;