@import "../../index.less";

.home_layout {
  background: rgb(255, 255, 255);
  height: 100vh;
}

.navbar {
  background: #fff;
}

.button {
  align-self: center;
}

.logo {
  float: left;
  margin: 16px 24px 16px 0;
}

#components-layout-demo-top-side-2 {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: @secondary-color;
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
  width: 100%;
}

.home_content {
  width: 100%;
}

/*------------ Header -----------------*/
.header {
  background: #ebe9f2;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 20px 20px 20px;
  width: 100%;
}

.header_content {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

/*------------ Content 1 (Sponsors) -----------------*/
.content_1 {
  background: #fff;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0px 20px 20px 20px;
}

.sponsors_logo_row {
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 20px 20px 20px;
  width: 100%;
}

.sponsors_logo_col {
  padding: 40px 40px 40px 40px;
}

/*------------ Content 2 (Features) -----------------*/
.content_2 {
  background: #fff;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 20px 20px 20px;
}

.content_2_row {
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0px 20px 0px 20px;
  width: 100%;
}

.content_2_content {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

/*------------ Content 3 (Use Case) -----------------*/
.content_3 {
  background: #fff;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 20px 20px 20px;
}

.use_case_row {
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 20px 20px 20px;
  width: 100%;
}

.use_case_col {
  padding: 40px 40px 40px 40px;
}

/*------------ Content 4 (Pricing) -----------------*/
.content_4 {
  background: #130936;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 20px 20px 20px;
}

/*------------ Footer -----------------*/
.footer {
  background: #fff;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 20px 20px 20px;
}

.pricing_row {
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 20px 20px 20px;
  width: 100%;
}

.pricing_col {
  padding: 40px 40px 40px 40px;
}


/* ---------- Custom scrollbar design ----------- */

::-webkit-scrollbar {
  width: 20px; /* For the size of vertical scrollbar */
  height: 20px; /* For the size of horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/*-------- Grid View -------*/
.grid-layout {
  background: #fff;
}

@primary-color: #270949;@tabs-title-font-size-sm: 12px;