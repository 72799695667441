.tab_pane_content {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page_sider {
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #270949;
  padding: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

}

/*--------- Insert Graph Pane ----------*/
.selection_bar {
  width: 100%;
  color:black;
}

.input_bar {
  width: 100%;
  color: black;
}

.preview_card {
  width: 100%;
  max-height: 325px;
  min-height: 200px;
  overflow-y: auto;
}

.preview_element {
  border: 1px solid;
  border-color: rgb(0, 0, 0);
  border-radius: 15%;
  margin-bottom: 25px;
  width: 100%;
  height: 150px;
}

.preview_button {
  width: 70%;
  margin: 0px 50px;
}




@primary-color: #270949;@tabs-title-font-size-sm: 12px;