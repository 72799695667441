.layout {
  height: 100vh;
  background: #fff;
}

.navbar {
  background: #fff;
}

.user_profile_row {
  background-color: #fff;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 100vh;
  padding: 20px 20px 20px 20px;
}

.user_profile_form {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

/* ---------- Custom scrollbar design ----------- */

::-webkit-scrollbar {
  width: 20px; /* For the size of vertical scrollbar */
  height: 20px; /* For the size of horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

@primary-color: #270949;@tabs-title-font-size-sm: 12px;