@import "../../index.less"; 

.layout {
	height: 100vh;
	background: #fff;
}

.navbar {
	background: #fff;
}

.url_row {
	background-color: #fff;
	align-items: center;
	align-content: center;
	padding: 20px 20px 20px 20px;
}

.url_form {
	width: 100%;
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
}

.param_col {
	padding: 5px 5px 5px 5px;
}

.json_format_col{
	padding: 10px 10px 10px 10px;
}

.json_format{
	border: 1px solid @secondary-color;
	cursor: pointer;

}

.json_format_image{
	opacity: 1;
	transition: .5s ease;
	backface-visibility: hidden;

}

.json_format:hover .json_format_image{
	opacity: 0.3;
  }

/* ---------- Custom scrollbar design ----------- */

::-webkit-scrollbar {
	width: 20px; /* For the size of vertical scrollbar */
	height: 20px; /* For the size of horizontal scrollbar */
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #d6dee1;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #a8bbbf;
}

@primary-color: #270949;@tabs-title-font-size-sm: 12px;